<template>
  <div class="box100" style="text-align: center;padding-top: 10%;color: #999;">
    <img src="../../assets/img/error.png">
    <p style="font-size: 50px;font-weight: bold;margin-top: 50px;">404</p>
    <p>页面走失了</p>
  </div>
</template>

<script>
export default {
  name: 'error'
}
</script>

<style scoped>

</style>
